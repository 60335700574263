<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="5" :sm="15">
            <a-form-item label="流程定义key">
              <a-input placeholder="请输入" v-model="queryParam.key" allowClear />
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="15">
            <a-form-item label="流程名称">
              <a-input placeholder="请输入流程名称" v-model="queryParam.name" allowClear />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="query">查询</a-button>
<!--              <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>-->
              <a-button style="margin-left: 8px" @click="reset">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-operator">
      <a-dropdown :disabled="selectedRowKeys.length === 0">
        <a-button type="danger" icon="delete" @click="delByIds(selectedRowKeys)">删除</a-button>
      </a-dropdown>
    </div>

    <!-- 数据表格 -->
    <a-table
      :loading="tableLoading"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      :rowKey="(record) => record.id"
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="page"
      :rowClassName="rowClassName"
      @change="changeTable"
    >
      <span slot="name" slot-scope="text">
        <a-tooltip>
          <template slot="title"> {{ text }} </template> {{ text }}
        </a-tooltip>
      </span>
      <span slot="index" slot-scope="text, row, index">{{ index + 1 }}</span>
      <span slot="logo" slot-scope="item">
        <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
      </span>

      <span slot="isSuspended" slot-scope="text">
        {{ text ? '挂起' : '激活' }}
      </span>
      <span slot="action" slot-scope="text, record">
        <a-popconfirm
          placement="topRight"
          title="该操作会一并 激活/挂起 所有当前实例?"
          @confirm="handleState(record)"
          okText="是"
          cancelText="否"
        >
          <a>{{ record.isSuspended ? '激活' : '挂起' }}</a>
        </a-popconfirm>
        <a-divider type="vertical" />
        <a @click="delByIds([record.id])">删除</a>
      </span>
    </a-table>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { getProfInfRuns, delProcessIns, processInsState } from '@/api/activiti'
import { checkPermission } from '@/utils/permissions'

export default {
  name: 'RunsProcess',
  components: {
    STable,
  },
  data() {
    return {
      tableData: [],
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      tableLoading: false, //表格loading
      visible: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      form: this.$form.createForm(this),
      mdl: {},
      permissions: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '流程实例ID',
          dataIndex: 'procInstId',
        },
        {
          title: '流程名称',
          dataIndex: 'name',
        },
        {
          title: '版本',
          dataIndex: 'version',
        },
        {
          title: '申请人',
          dataIndex: 'applyer',
        },
        {
          title: '标识key',
          dataIndex: 'key',
        },
        {
          title: '当前环节',
          dataIndex: 'currTaskName',
        },
        {
          title: '部署ID',
          dataIndex: 'deployId',
        },
        {
          title: '状态',
          dataIndex: 'isSuspended',
          scopedSlots: { customRender: 'isSuspended' },
        },
        {
          title: '操作',
          width: '150px',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      // 加载数据方法 必须为 Promise 对象
      // loadData: parameter => {
      //   return getProfInfRuns(Object.assign(parameter, this.queryParam))
      // },
      selectedRowKeys: [],
      selectedRows: [],
      addEnable: checkPermission('activiti:prof:add'),
      editEnabel: checkPermission('activiti:prof:edit'),
      removeEnable: checkPermission('activiti:prof:remove'),
    }
  },
  filters: {},
  created() {
    this.getData()
  },
  methods: {
    // 隔行换色
    rowClassName(record, index) {
      let className = 'light-row'
      if (index % 2 === 1) className = 'dark-row'
      return className
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleOk() {
      this.getData()
      console.log('handleSaveOk')
    },
    handleState(record) {
      const state = record.isSuspended ? 'active' : 'suspend'
      processInsState(record.procInstId, state).then((res) => {
        if (res.code === 200) {
          record.isSuspended = !record.isSuspended
        }
      })
    },
    delByIds(ids) {
      const _that = this
      _that.$confirm({
        title: '确认删除',
        content: '确认要删除选择的流程?',
        onOk() {
          delProcessIns({ ids: ids.join(','), reason: '流程中止' }).then((res) => {
            if (res.code === 200) {
              _that.$message.success(`删除成功`)
              _that.handleOk()
            } else {
              _that.$message.error(res.msg)
            }
          })
        },
        onCancel() {},
      })
    },

    /**
     * 更改分页
     */
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    // 查询
    query(){
      this.page.current = 1;
      this.getData();
    },
    // 重置
    reset(){
      this.queryParam = {}
      this.page.current = 1;
      this.page.pageSize = 10;
      this.getData()
    },

    getData() {
      this.selectedRows = []
      this.selectedRowKeys = []
      this.tableLoading = true
      return getProfInfRuns({
        pageNumber: this.page.current,
        pageSize: this.page.pageSize,
        ...this.queryParam,
      })
        .then((res) => {
          if (res.code === 200) {
            const { total, rows } = res
            this.page.total = total
            this.tableData = rows
          }
        })
        .finally(() => (this.tableLoading = false))
    },
  },
  watch: {
    /*
      'selectedRows': function (selectedRows) {
        this.needTotalList = this.needTotalList.map(item => {
          return {
            ...item,
            total: selectedRows.reduce( (sum, val) => {
              return sum + val[item.dataIndex]
            }, 0)
          }
        })
      }
      */
  },
}
</script>
<style lang="less" scoped>
::v-deep .ant-table-tbody > .light-row {
  background: #fff !important;
}
::v-deep .ant-table-tbody > .dark-row {
  background: #fafafa !important;
}

</style>